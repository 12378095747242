$(document).on('turbolinks:load', function(e) {
  if (!e.originalEvent.data.timing.visitStart) {
    $(document).on('click', '[data-id="fillCustomerAddress"]', e => {
      const $list = $('.fill-address .dropdown-address-list');
      if ($list.hasClass('show')) {
        $list.removeClass('show');
        return false;
      }
      const $form = $(e.target).closest('form')
      const postal_code = $form.find('[name*="[postal_code]"]').val()
      const url = `/postal_codes/zip/${postal_code}`;
      $.getJSON(url, null, function(data, status) {
        if (data.length === 1) {
          const { todoufuken_code, region, town, build } = data[0];
          $form.find('[name*="[todoufuken_code]"]').val(todoufuken_code)
          $form.find('[name*="[address1]"]').val(`${region || ''}${town || ''}`)
          $form.find('[name*="[address2]"]').val(build || '')
        } else {
          const $template = $list.find('.address-box.template');
          $list.find('.address-box:not(.template)').remove();
          data.forEach((address, index) => {
            const { todoufuken, region, town, build } = address;
            let $node = $template.clone(false).removeClass('template')
            const address1 = `${region || ''}${town || ''}`;
            const address2 = (build || '');
            $node.attr('data-todoufuken', todoufuken.code);
            $node.attr('data-address1', address1);
            $node.attr('data-address2', address2);
            $node.find('.todoufuken').text(todoufuken.name);
            $node.find('.address1').text(address1);
            $node.find('.address2').text(address2);
            $node.appendTo($list);
          });
          $list.addClass('show');
        }
      });
      return false;
    });

    $(document).on('click', '.dropdown-address-list .address-box:not(.template)', function(e) {
      const $this = $(this)
      const $form = $this.closest('form')
      $form.find('[name*="[todoufuken_code]"]').val($this.data('todoufuken'))
      $form.find('[name*="[address1]"]').val($this.data('address1'))
      $form.find('[name*="[address2]"]').val($this.data('address2'))
    });

    $(document).on('click', '*:not([data-id="fillCustomerAddress"])', function(e) {
      const $list = $('.fill-address .dropdown-address-list');
      $list.removeClass('show');
    });
  }
});
