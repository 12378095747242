$(document).on('turbolinks:visit', function() {
  // Turbolinksでの画面遷移時にMagnific Popupを閉じる
  $.magnificPopup.close();
});

$(document).on('turbolinks:load', function(e) {
  if (!e.originalEvent.data.timing.visitStart) {
    function topSearchShow() {
      $('#top-search').slideDown().animate({
        opacity: 1
      }, {
        queue: false,
        duration: 300
      })
    }
    function topSearchHide() {
      $('#top-search').slideUp().animate({
        opacity: 0
      }, {
        queue: false,
        duration: 300
      })
    }
    const $window = $(window)
    $window.on('scroll', e => {
      if ($window.scrollTop() > 100) {
        $('.pagetop').fadeIn()
        if ($window.width() < 680) {
          topSearchHide()
        }
      } else {
        $('.pagetop').fadeOut();
        topSearchShow()
      }
    })
    $window.on('resize', e => {
      if ($window.width() < 680 && $window.scrollTop() > 100) {
        topSearchHide()
      } else {
        topSearchShow()
      }
    })
    $('.pagetop').on('click', e => {
      $('body,  html').animate({
        scrollTop: 0
      }, 500);
      return false;
    })
  }

  var $header = $('#top-head');
  // if (!e.originalEvent.data.timing.visitStart) {
  //   Nav Fixed
  //   $(window).scroll(function() {
  //     if ($(window).scrollTop() > 350) {
  //       $header.addClass('fixed');
  //     } else {
  //       $header.removeClass('fixed');
  //     }
  //   });
  // }

  // Nav Toggle Button
  $('#nav-toggle').click(function(){
    $header.toggleClass('open');
  });

  var position;
  // タップイベント
  $('body').bind("touchstart", function(event) {
    position = getPosition(event);
  });
  // スワイプイベント
  $('body').bind("touchmove", function(event) {
    if (Math.abs(position - getPosition(event)) > 10) {
      toggleClose();
    }
  });

  // 座標を取得
  function getPosition(event) {
    var pageY = 0;
    if (event.originalEvent.touches) {
      pageY = event.originalEvent.touches[0].pageY;
    } else {
      pageY = event.pageY;
    }
    return pageY;
  }

  // メニューを閉じる
  function toggleClose()
  {
    if ($header.hasClass('open')) {
      $header.removeClass('open');
    }
  }

  $('.notice a.close').on('click', function () {
    $(this).closest('.notice').slideUp();
  });
});

$(document).on('turbolinks:load', function(e) {
  // アンカーリンクでの画面遷移時にスクロール位置を調整
  const urlHash = location.hash;
  if (urlHash) {
    const $target = $(urlHash);
    if ($target.length > 0) {
      $('html, body').animate({ scrollTop: 0 }, 0);
      setTimeout(() => {
        const headerHeight = $('#header').outerHeight();
        const position = $target.offset().top - headerHeight;
        $('html, body').animate({ scrollTop: position }, 500);
      }, 100)
    }
  }
});
