$(document).on('turbolinks:load', function() {
  $('#slider').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          centerMode: false,
          variableWidth: false,
        }
      },
    ]
  });
  $('#top-category-menu').slick({
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    adaptiveHeight: false,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        }
      },
    ]
  })
});
