$(document).on('turbolinks:load', function(e) {
  if (!e.originalEvent.data.timing.visitStart) {
    $pageTopBtn = $('.genlpwrap .page_top_btn')
    $(window).on('scroll', e => {
      if ($(window).scrollTop() > 800) {
        $pageTopBtn.addClass('show');
      }else {
        $pageTopBtn.removeClass('show');
      }
    })
  }

  let timerId = null
  let mailAddress = null

  const $inputLoginMail = $('[data-id="inputLoginMail"]')
  const $formLp1Login = $('[data-id="formLp1Login"]')
  const $inputOrderMail = $('[data-id="inputOrderMail"]')
  const $inputLoginPassword = $('[data-id="inputLoginPassword"]')
  const $inputDummyPassword = $('[data-id="inputDummyPassword"]')

  // メールアドレス入力フォームの内容が変化する度に問い合わせる
  const onChange = function() {
    const val = $inputOrderMail.val()
    if (mailAddress != val) {
      mailAddress = val
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => {
        timerId = null
        $.ajax({
          type: 'get',
          url: '/landing/mochi-genmai/customer_exists',
          data: { mail: mailAddress }
        }).done(data => {
          $('[data-id="blkDummyPassword"]').removeClass('hidden')
        }).fail(err => {
          $('[data-id="blkDummyPassword"]').addClass('hidden')
        })
      }, 1000)
    }
  }
  $inputOrderMail.on('change', onChange)
    .on('input', onChange)
    .on('keypress', onChange)
    .on('keyup', function(e) {
      if (e.keyCode == 46 || e.keyCode == 8) onChange()
    }).trigger('change')

  // ログインボタン押下でメアド/パスワードをコピーしてログイン処理
  $('[data-id="buttonLp1Login"]').on('click', e => {
    const mail = $inputOrderMail.val()
    const passwd = $inputDummyPassword.val()
    $inputLoginMail.val(mail)
    $inputLoginPassword.val(passwd)
    $formLp1Login.submit()
    return false
  })
})
